import React, { useEffect, createContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useBrinkStoreConfig } from "./utils/useBrinkStoreConfig"
import BrinkApi from "./utils/BrinkApi"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"
import { navigate } from "gatsby"
import { useCookies } from "react-cookie"

export const BrinkContext = createContext()

const BRINK_COMMERCE_SESSION_COOKIE = "brinkcommerceSessionId"
const BRINK_CART_LOCAL_STORAGE_KEY = "BrinkCart"
const SHOPPER_REFERENCE_COOKIE = "shopperReference"
const SHOPPER_STORE_COOKIE = "shopperStore"
const CART_CLOSED = "CLOSED"

export const BrinkContextProvider = ({ children }) => {
  const BRINKCOMMERCE_API_URL = process.env.GATSBY_BRINKCOMMERCE_API_URL
  const ADYEN_ENVIRONMENT = process.env.GATSBY_ADYEN_ENVIRONMENT
  const ADYEN_CLIENT_KEY = process.env.GATSBY_ADYEN_CLIENT_KEY
  const ENABLE_INGRID_DELIVERY_CHECKOUT = /true/i.test(process.env.GATSBY_ENABLE_INGRID_DELIVERY_CHECKOUT)
  const ENABLE_BRINK_SHIPPING = /true/i.test(process.env.GATSBY_ENABLE_BRINK_SHIPPING ?? "true")
  const ADYEN_SESSION_ENABLED = /true/i.test(process.env.GATSBY_ADYEN_SESSION_ENABLED)
  const STOCK_VERSION = process.env.GATSBY_STOCK_VERSION ?? "v2"
  const DEFAULT_CURRENCY = "SEK"

  const [cookies, _setCookie] = useCookies([
    BRINK_COMMERCE_SESSION_COOKIE,
    SHOPPER_REFERENCE_COOKIE,
    SHOPPER_STORE_COOKIE
  ])

  const useMountEffect = (fun) => useEffect(fun, [])

  const storeOptions = useBrinkStoreConfig()
  const { i18n } = useTranslation()

  const isBrowser = typeof window !== "undefined"

  const setCookie = (name, value, days) => {
    isBrowser &&
      _setCookie(name, value, {
        path: "/",
        expires: moment().add(days, "days").toDate()
      })
  }

  const [stores] = useState(() => {
    const stores = storeOptions
      .map((it) => ({
        currencyUnit: it.currencyUnit,
        countryCode: it.countryCode,
        languageCode: it.languageCode,
        taxPercentage: it.tax
      }))
      .sort((a, b) => a.countryCode.localeCompare(b.countryCode))

    return stores
      .filter((store) => store.currencyUnit !== DEFAULT_CURRENCY)
      .concat(stores.filter((store) => store.currencyUnit === DEFAULT_CURRENCY))
  })

  useMountEffect(() => {
    const fetchAndSetStore = async (stores) => {
      const brinkApi = new BrinkApi({
        url: BRINKCOMMERCE_API_URL,
        setNotification: setNotification
      })
      const countryCode = cookies[SHOPPER_STORE_COOKIE]
        ? cookies[SHOPPER_STORE_COOKIE]
        : (await brinkApi.getCountryCode()).countryCode
      const store =
        stores.find((store) => store.countryCode === countryCode) ||
        stores.find((store) => store.currencyUnit === DEFAULT_CURRENCY)
      setCurrentStore(store)
    }

    const getProductVariantsOutOfStock = async () => {
      const brinkApi = new BrinkApi({
        url: BRINKCOMMERCE_API_URL,
        setNotification: setNotification
      })
      const productsStock = await brinkApi.getOutOfStock()
      setProductVariantsOutOfStock(productsStock.map((p) => p.productId))
    }

    fetchAndSetStore(stores)
    STOCK_VERSION === "v1" && getProductVariantsOutOfStock()
    isCartExpired() && closeCart()
  })

  const [productVariantsOutOfStock, setProductVariantsOutOfStock] = useState([])

  const [currentStore, _setCurrentStore] = useState(() => {
    const countryCode = cookies[SHOPPER_STORE_COOKIE] ? cookies[SHOPPER_STORE_COOKIE] : "SE"
    return stores.find((store) => store.countryCode === countryCode)
  })

  const shouldUpdateStore = (store, cart) => cart?.store?.countryCode !== store.countryCode

  const [countryWhiteList] = useState(stores.map((s) => s.countryCode))
  const [brinkSessionId, _setBrinkSessionId] = useState(() => cookies[BRINK_COMMERCE_SESSION_COOKIE])

  const setCurrentStore = (store) => {
    setShippingAddress({ country: store.countryCode })
    setCookie(SHOPPER_STORE_COOKIE, store.countryCode, 365)
    if (shouldUpdateStore(store, cart)) {
      updateCartStore(store)
      _setShippingMethod(undefined)
    }
    _setCurrentStore(store)
  }

  const setBrinkSessionId = (sessionId) => {
    setCookie(BRINK_COMMERCE_SESSION_COOKIE, sessionId, 2)
    _setBrinkSessionId(sessionId)
  }

  const languageCode = storeOptions.find((s) => s.languageCode === i18n.language.slice(0, 2))
    ? i18n.language.slice(0, 2)
    : "en"

  const [supportedLanguages] = useState(() => [{ label: "English", code: "en" }])

  const [shopperReference] = useState(() => {
    if (!cookies[SHOPPER_REFERENCE_COOKIE]) {
      setCookie(SHOPPER_REFERENCE_COOKIE, uuidv4(), 365)
    }
    return cookies[SHOPPER_REFERENCE_COOKIE]
  })

  const [notification, setNotification] = useState({
    [NotificationTypes.CART]: Object.assign({}, Notification),
    [NotificationTypes.CHECKOUT]: Object.assign({}, Notification)
  })

  const clearNotification = (type) => {
    setNotification({ [type]: Object.assign({}, Notification) })
  }

  const getCart = (localstorageCart) => {
    const emptyCart = {
      cartItems: [],
      totPrice: 0,
      totPriceWithDiscount: 0,
      totDiscountAmount: 0,
      discounts: {}
    }
    try {
      return localstorageCart ? JSON.parse(decodeUnicode(localstorageCart)) : emptyCart
    } catch (error) {
      return emptyCart
    }
  }

  const [shippingAddress, setShippingAddress] = useState({})
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [order, setOrder] = useState({})
  const [searchQuery, setSearchQuery] = useState(null)
  const [searchId, setSearchId] = useState(null)
  const [discountCode, setDiscountCode] = useState(null)
  const [cart, _setCart] = useState(() => {
    const localstorageCart = isBrowser ? localStorage.getItem(BRINK_CART_LOCAL_STORAGE_KEY) : null
    const cart = getCart(localstorageCart)
    const rules =
      cart.discounts && cart.discounts.rules && cart.discounts.rules.filter((r) => r.ruleType === "DISCOUNTCODE")
    const discountCode = rules && rules.length > 0 ? rules[0].ruleData.discountCode : null
    setDiscountCode(discountCode)
    return cart
  })
  const [shippingMethod, _setShippingMethod] = useState(() => {
    const shipping = cart && cart.cartItems.find((c) => c.type === "shippingOption")
    return shipping
  })

  const setShippingMethod = async (shippingOption) => {
    _setShippingMethod(shippingOption)
    await updateProductVariantInCart({
      productVariantId: shippingOption.id,
      quantity: 1,
      shipping: true
    })
  }

  const removeShippingMethod = () => _setShippingMethod(null)

  const updateShippingAddress = (values) => setShippingAddress(Object.assign({}, shippingAddress, values))

  const setCart = (cart) => {
    if (isBrowser) {
      localStorage.setItem(BRINK_CART_LOCAL_STORAGE_KEY, encodeUnicode(JSON.stringify(cart)))
    }
    const rules =
      cart.discounts && cart.discounts.rules && cart.discounts.rules.filter((r) => r.ruleType === "DISCOUNTCODE")

    const discountCode = rules && rules.length > 0 ? rules[0].ruleData.discountCode : null
    setDiscountCode(discountCode)
    _setCart(cart)
  }

  const removeProductFromCart = (productVariantId, rowId) => {
    const p = cart.cartItems.find((p) => p.id === productVariantId)
    updateProductVariantInCart({ productVariantId, rowId, quantity: 0 })

    typeof window.gtag !== "undefined" &&
      window.gtag("event", "remove_from_cart", {
        items: [
          {
            id: productVariantId,
            name: p.name,
            brand: "BrinkCommerce",
            category: p.category,
            quantity: p.quantity
          }
        ]
      })
  }

  const plusOneProductVariantToCart = (productVariantId, rowId) => {
    const p = cart.cartItems.find((p) => p.id === productVariantId)
    updateProductVariantInCart({
      productVariantId,
      rowId,
      quantity: 1
    })

    typeof window.gtag !== "undefined" &&
      window.gtag("event", "add_to_cart", {
        items: [
          {
            id: productVariantId,
            name: p.name,
            brand: "BrinkCommerce",
            category: p.category,
            quantity: 1
          }
        ]
      })
  }

  const minusOneProductVariantToCart = (productVariantId, rowId) => {
    const p = cart.cartItems.find((p) => p.id === productVariantId)
    updateProductVariantInCart({
      productVariantId,
      rowId,
      quantity: -1
    })

    typeof window.gtag !== "undefined" &&
      window.gtag("event", "remove_from_cart", {
        items: [
          {
            id: productVariantId,
            name: p.name,
            brand: "BrinkCommerce",
            category: p.category,
            quantity: 1
          }
        ]
      })
  }

  const addProductVariantsToCart = (productVariantId, addons = [], quantity, openCart = false) => {
    updateProductVariantInCart({
      productVariantId: productVariantId,
      addons: addons,
      quantity: quantity,
      openCart: openCart
    })

    typeof window.gtag !== "undefined" &&
      window.gtag("event", "add_to_cart", {
        items: [
          {
            id: productVariantId,
            brand: "BrinkCommerce",
            quantity: quantity
          }
        ]
      })
  }

  const removeProductVariantsFromCart = (productVariantId, quantity) => {
    typeof window.gtag !== "undefined" &&
      window.gtag("event", "remove_from_cart", {
        items: [
          {
            id: productVariantId,
            brand: "BrinkCommerce",
            quantity: quantity
          }
        ]
      })

    updateProductVariantInCart({
      productVariantId: productVariantId,
      quantity: quantity
    })
  }

  const removeDiscountFromCart = async () => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })
    try {
      const products = shippingMethod ? [{ id: shippingMethod.id, quantity: 1 }] : []
      const responseData = await brinkApi.removeDiscount({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        products
      })
      setBrinkSessionId(responseData.jwtToken)
      setCart(responseData.cart)
      setIsLoading(false)

      typeof window.gtag !== "undefined" &&
        window.gtag("event", "checkout_progress", {
          items: cart.cartItems.map((i) => ({
            id: i.id,
            name: i.name,
            brand: "BrinkCommerce",
            category: i.category,
            quantity: i.quantity
          }))
        })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
    }
  }

  const getProducts = ({ productVariantId, quantity, rowId, addons }, shipping) => {
    const products = [{ id: productVariantId, quantity, addons, ...(rowId && { rowId }) }]
    return !shipping && shippingMethod ? [...products, { id: shippingMethod.id, quantity: 1 }] : products
  }

  const updateProductVariantInCart = async ({
    productVariantId,
    addons = [],
    rowId = null,
    quantity,
    openCart = false,
    shipping = false
  }) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })
    let responseData
    const products = getProducts(
      {
        productVariantId,
        quantity,
        rowId,
        addons: addons.map((addon) => ({ productId: addon.id, options: addon?.options || [] }))
      },
      shipping
    )
    try {
      if (isCartClosed()) {
        localStorage.removeItem(BRINK_CART_LOCAL_STORAGE_KEY)
        responseData = await brinkApi.syncCart({
          action: "post",
          products: products,
          currencyUnit: currentStore.currencyUnit,
          languageCode: languageCode,
          countryCode: currentStore.countryCode,
          taxPercentage: currentStore.taxPercentage
        })
      } else {
        responseData = await brinkApi.syncCart({
          headers: { Authorization: `Bearer ${brinkSessionId}` },
          action: "put",
          products: products
        })
      }
      if (responseData.error) {
        setIsLoading(false)

        if (responseData.error === "Cart closed") {
          setIsCartOpen(false)
          closeCart()
          navigate("/")
        }
        const messages = {
          "Out of stock": "Product is out of stock",
          "Out of stock with reservations": "Product is reserved by someone else",
          "Reservation quantity limit exceeded": "Cart limit reached"
        }
        setNotification({
          [NotificationTypes.CART]: {
            severity: NotificationSeverityLevel.ERROR,
            message: messages[responseData.error] ?? "Problem adding product to cart",
            event: NotificationEvent.UPDATE_CART,
            processing: false
          }
        })
        return
      }
      responseData.jwtToken && setBrinkSessionId(responseData.jwtToken)
      if (responseData.cart) {
        if (responseData.cart.cartItems.length === 0) {
          closeCart()
        } else {
          setCart(responseData.cart)
        }
      }
      setIsLoading(false)
      openCart && setIsCartOpen(true)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
    }
  }

  const closeCart = (clearShippingMethod = false) => {
    const closedCart = {
      ...cart,
      state: CART_CLOSED,
      cartItems: []
    }
    setCart(closedCart)
    clearShippingMethod && _setShippingMethod(null)
  }

  const isCartClosed = () => (cart.state || "").toUpperCase() === CART_CLOSED || !brinkSessionId

  const isCartExpired = () => isCartClosed() && !brinkSessionId

  const addDiscount = async ({ code }) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    const products = shippingMethod ? [{ id: shippingMethod.id, quantity: 1 }] : []
    try {
      const responseData = await brinkApi.addDiscount({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        code: code,
        products
      })
      setIsLoading(false)
      if (responseData) {
        setCart(responseData.cart)

        typeof window.gtag !== "undefined" &&
          window.gtag("event", "checkout_progress", {
            items: cart.cartItems.map((i) => ({
              id: i.id,
              name: i.name,
              brand: "BrinkCommerce",
              category: i.category,
              quantity: i.quantity
            })),
            coupon: code
          })
      }
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
    }
  }

  const updateCartStore = async (store) => {
    if (!isCartClosed()) {
      const brinkApi = new BrinkApi({
        url: BRINKCOMMERCE_API_URL,
        setNotification: setNotification
      })

      const responseData = await brinkApi.updateCartStore({
        headers: { Authorization: brinkSessionId },
        countryCode: store.countryCode,
        languageCode: store.languageCode
      })
      if (responseData.error) {
        const navigation = {
          "Out of stock": "/error-out-of-stock/",
          "Out of stock with reservations": "/error-out-of-stock/"
        }
        navigate(navigation[responseData.error] || "/error/", {
          state: {
            error: responseData.error
          }
        })

        return
      }
      setCart(responseData.cart)
    }
  }

  const makePayment = async (order, paymentMethod, storePaymentMethod, browserInfo) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      const responseData = await brinkApi.makePayment({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        orderId: order.id,
        paymentMethod: paymentMethod,
        storePaymentMethod: storePaymentMethod,
        shopperReference: shopperReference,
        browserInfo: browserInfo,
        redirect: getRedirect()
      })
      if (responseData.error) {
        if (responseData.error === "Order is already successful") {
          closeCart()
        }
        const navigation = {
          "Out of stock": "/error-out-of-stock/",
          "Order is already successful": "/"
        }
        navigate(navigation[responseData.error] || "/error/")
        return null
      }
      return responseData
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const getReturnUrl = () =>
    `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }/router/`

  const getRedirect = () => {
    const baseUrl = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }`
    return {
      success: `${baseUrl}/success/`,
      error: `${baseUrl}/checkout/`,
      canceled: `${baseUrl}/checkout/`,
      default: `${baseUrl}/`
    }
  }

  const getPaymentMethods = async () => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      return await brinkApi.getPaymentMethods({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        currencyUnit: currentStore.currencyUnit,
        countryCode: shippingAddress.country,
        amount: cart.totalPriceWithDiscount,
        shopperReference: shopperReference,
        languageCode: languageCode
      })
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const searchProducts = async (searchQuery) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      const products = (await brinkApi.searchProducts({ query: searchQuery })).products.filter(
        (product) => product.type === "product" || product.type === "productVariant"
      )
      setIsLoading(false)
      return products
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
    }
  }

  const makeDetailsCall = async (orderId, details) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })
    try {
      return await brinkApi.makeDetailsCall({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        orderId: orderId,
        details: details
      })
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const cartToOrder = async () => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      const responseData = await brinkApi.cartToOrder({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        email: shippingAddress.email,
        shippingAddress: toOrderShippingAddress(),
        billingAddress: toOrderBillingAddress()
      })
      if (responseData.error) {
        if (responseData.error === "Cart closed" || responseData.error === "Order is already successful") {
          closeCart()
        }
        const navigation = {
          "Out of stock": "/error-out-of-stock/",
          "Out of stock with reservations": "/error-out-of-stock/",
          "Order is already successful": "/"
        }
        navigate(navigation[responseData.error] || "/error/")
        return null
      }
      return responseData
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const cartToAdyenOrder = async (blockedPaymentMethods = []) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      const responseData = await brinkApi.cartToAdyenOrder({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        email: shippingAddress.email,
        shippingAddress: toOrderShippingAddress(),
        billingAddress: toOrderBillingAddress(),
        blockedPaymentMethods,
        storePaymentMethod: true,
        shopperReference: shopperReference,
        languageCode,
        returnUrl: getReturnUrl()
      })
      if (responseData.error) {
        if (responseData.error === "Cart closed" || responseData.error === "Order is already successful") {
          closeCart()
        }
        const navigation = {
          "Out of stock": "/error-out-of-stock/",
          "Out of stock with reservations": "/error-out-of-stock/",
          "Order is already successful": "/"
        }
        navigate(navigation[responseData.error] || "/error/")
        return null
      }
      return responseData
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const cartToKlarnaOrder = async (options = {}) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    const merchantUrls = {
      terms: `${window.location.origin}/terms-and-conditions`,
      checkout: `${window.location.origin}/checkout/?klarnaOrderId={checkout.order.id}`,
      confirmation: `${window.location.origin}/success-klarna/?klarnaOrderId={checkout.order.id}`
    }

    const merchantData = {
      errorPage: `${window.location.origin}/error/`,
      errorOutOfStockPage: `${window.location.origin}/error-out-of-stock/`
    }

    try {
      const responseData = await brinkApi.cartToKlarnaOrder({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        merchantUrls,
        merchantData: JSON.stringify(merchantData),
        options,
        shippingAddress
      })
      if (responseData.error) {
        if (responseData.error === "Klarna order creation conflict") return null
        if (responseData.error === "Cart closed" || responseData.error === "Order is already successful") {
          closeCart()
        }
        const navigation = {
          "Out of stock": "/error-out-of-stock/",
          "Out of stock with reservations": "/error-out-of-stock/",
          "Order is already successful": "/",
          "Missing product": "/"
        }
        navigate(navigation[responseData.error] || "/error/")
        return null
      }
      return responseData
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const getKlarnaOrder = async (orderId) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      return await brinkApi.getKlarnaOrder({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        orderId
      })
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const getShippingOptions = async (countryCode) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      const shippingOptions = await brinkApi.getShippingOptions({
        headers: { Authorization: `Bearer ${brinkSessionId}` },
        countryCode
      })

      setIsLoading(false)
      return shippingOptions
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
    }
  }

  const getSetCart = async (sessionId, token) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      const cart = await brinkApi.getCart(sessionId)
      if (cart.state !== "ACTIVE") {
        console.error("Cart closed")
        setIsLoading(false)
        closeCart()
        navigate("/error/")
        return
      }
      setBrinkSessionId(token)
      const shipping = cart.cartItems.find((c) => c.type === "shippingOption")
      shipping && _setShippingMethod(shipping)
      setCart(cart)
      return cart
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      navigate("/error/")
      return null
    }
  }

  const syncIngridDeliveryCheckout = (withSearchAddress = true) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })
    const searchAddress = {
      postalCode: shippingAddress.postalCode || "",
      countryCode: shippingAddress.country || cart.store.countryCode
    }
    setIsLoading(true)
    return brinkApi
      .ingridDeliveryCheckout(brinkSessionId, { ...(withSearchAddress && { searchAddress }) })
      .then(({ cart, ingrid }) => {
        setCart(cart)
        return ingrid
      })
      .finally(() => setIsLoading(false))
  }

  const getStocks = async (productIds) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    try {
      if (STOCK_VERSION === "v2") {
        return await brinkApi.getStocksV2(productIds, currentStore.countryCode).then((result) =>
          result.products.map((product) => ({
            id: product.id,
            availableQuantity: product.availableQuantity,
            isAvailable: product.isAvailable
          }))
        )
      }
      return await brinkApi.getStocksV1(productIds).then((stocks) =>
        stocks.map((stock) => ({
          id: stock.productId,
          availableQuantity: stock.availableQuantity,
          isAvailable: stock.isAvailable
        }))
      )
    } catch (error) {
      setIsLoading(false)
      return []
    }
  }

  const getOrderConfirmation = async (orderId, signature) => {
    const brinkApi = new BrinkApi({
      url: BRINKCOMMERCE_API_URL,
      setNotification: setNotification
    })

    return brinkApi.getOrderConfirmation(orderId, signature)
  }

  const toOrderShippingAddress = () => ({
    givenName: shippingAddress.firstName,
    familyName: shippingAddress.lastName,
    phone: shippingAddress.phone,
    streetAddress: shippingAddress.address,
    houseNumberOrName: shippingAddress.houseNumberOrName,
    postalCode: shippingAddress.postalCode,
    city: shippingAddress.city,
    region: shippingAddress.region,
    country: shippingAddress.country
  })

  const toOrderBillingAddress = () => ({
    givenName: shippingAddress.firstName,
    familyName: shippingAddress.lastName,
    phone: shippingAddress.phone,
    streetAddress: shippingAddress.address,
    houseNumberOrName: shippingAddress.houseNumberOrName,
    postalCode: shippingAddress.postalCode,
    city: shippingAddress.city,
    region: shippingAddress.region,
    country: shippingAddress.country
  })

  const contextObjects = {
    ADYEN_CLIENT_KEY,
    ADYEN_ENVIRONMENT,
    ADYEN_SESSION_ENABLED,
    makePayment,
    cartToOrder,
    cartToKlarnaOrder,
    getKlarnaOrder,
    getPaymentMethods,
    addDiscount,
    removeProductFromCart,
    plusOneProductVariantToCart,
    minusOneProductVariantToCart,
    addProductVariantsToCart,
    removeProductVariantsFromCart,
    removeDiscountFromCart,
    getShippingOptions,
    discountCode,
    cart,
    setNotification,
    clearNotification,
    notification,
    languageCode,
    isLoading,
    setIsLoading,
    isCartOpen,
    setIsCartOpen,
    shippingAddress,
    setShippingAddress,
    updateShippingAddress,
    shippingMethod,
    setShippingMethod,
    removeShippingMethod,
    productVariantsOutOfStock,
    order,
    setOrder,
    closeCart,
    isCartClosed,
    isCartExpired,
    makeDetailsCall,
    stores,
    supportedLanguages,
    setSearchQuery,
    searchQuery,
    searchProducts,
    setCurrentStore,
    currentStore,
    searchId,
    setSearchId,
    countryWhiteList,
    getSetCart,
    getStocks,
    syncIngridDeliveryCheckout,
    ENABLE_INGRID_DELIVERY_CHECKOUT,
    ENABLE_BRINK_SHIPPING,
    getOrderConfirmation,
    cartToAdyenOrder
  }

  return <BrinkContext.Provider value={contextObjects}>{children}</BrinkContext.Provider>
}

const Notification = {
  event: null,
  severity: null,
  message: null,
  processing: null
}

export const NotificationTypes = {
  CART: "CART",
  CHECKOUT: "CHECKOUT"
}

export const NotificationSeverityLevel = {
  ERROR: "ERROR",
  INFO: "INFO"
}

export const NotificationEvent = {
  UPDATE_CART: "UPDATE_CART",
  CREATE_PAYMENT_CHECKOUT: "CREATE_PAYMENT_CHECKOUT",
  PROCESSING_PAYMENT: "PROCESSING_PAYMENT",
  APPLYING_DISCOUNT_CODE: "APPLYING_DISCOUNT_CODE",
  REMOVE_DISCOUNT_CODE: "REMOVE_DISCOUNT_CODE",
  PROCESSING_CONFIRMATION: "PROCESSING_CONFIRMATION"
}

const encodeUnicode = (str) =>
  btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => String.fromCharCode("0x" + p1)))

const decodeUnicode = (str) =>
  decodeURIComponent(
    atob(str)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  )
