import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: {
      default: ["en"]
    },
    load: "all",
    keySeparator: false,
    debug: process.env.NODE_ENV === "development",
    returnObjects: true,
    ns: ["translations"],
    defaultNS: "translations",
    supportedLngs: ["en", "zh"],
    resources: {
      zh: {
        translations: require("./zh/translations.json")
      },
      en: {
        translations: require("./en/translations.json")
      }
    },
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true
    }
  })

export default i18next
