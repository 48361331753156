import axios from "axios"
import { NotificationTypes, NotificationSeverityLevel, NotificationEvent } from "../BrinkContext"

export default class BrinkApi {
  constructor({ url, setNotification }) {
    this.orderUrl = `${url}/orderv1`
    this.productUrl = `${url}/productv1`
    this.integrationUrl = `${url}/integrationv1`
    this.stockUrl = `${url}/stockv2`
    this.setNotification = setNotification
  }

  async syncCart({ action, headers = {}, products, countryCode, currencyUnit, languageCode, taxPercentage }) {
    try {
      this.setNotification({
        [NotificationTypes.CART]: {
          severity: NotificationSeverityLevel.INFO,
          message: "Adding to cart",
          event: NotificationEvent.UPDATE_CART,
          processing: true
        }
      })
      let data = {
        products: products
      }
      if (action === "post") {
        data.store = {
          countryCode: countryCode,
          currencyUnit: currencyUnit,
          languageCode: languageCode,
          taxPercentage: taxPercentage
        }
      }
      const response = await axios({
        method: action,
        url: `${this.orderUrl}/carts`,
        headers: headers,
        data: data,
        validateStatus: (status) => [404, 403, 400, 200].includes(status)
      })

      this.setNotification({
        [NotificationTypes.CART]: {
          severity: NotificationSeverityLevel.INFO,
          message: "Cart successfully updated",
          event: NotificationEvent.UPDATE_CART,
          processing: false
        }
      })
      return response.data
    } catch (error) {
      this.setNotification({
        [NotificationTypes.CART]: {
          severity: NotificationSeverityLevel.ERROR,
          message: "Problem adding product to cart",
          event: NotificationEvent.UPDATE_CART,
          processing: false
        }
      })
      console.error(error)
      throw new Error("Error syncing cart")
    }
  }

  async addDiscount({ headers = {}, code, products }) {
    try {
      this.setNotification({
        [NotificationTypes.CART]: {
          severity: NotificationSeverityLevel.INFO,
          message: "Adding discount code",
          event: NotificationEvent.APPLYING_DISCOUNT_CODE,
          processing: true
        }
      })
      const response = await axios({
        method: "put",
        url: `${this.orderUrl}/carts`,
        headers: headers,
        data: {
          code: code,
          products: products
        }
      })
      if (isValidDiscount(response)) {
        console.log(response)
        this.setNotification({
          [NotificationTypes.CART]: {
            severity: NotificationSeverityLevel.INFO,
            message: "Discount code successfully added",
            event: NotificationEvent.APPLYING_DISCOUNT_CODE,
            processing: false
          }
        })
        return response.data
      }
      console.error(response)
      this.setNotification({
        [NotificationTypes.CART]: {
          severity: NotificationSeverityLevel.ERROR,
          message: "Invalid discount code",
          event: NotificationEvent.APPLYING_DISCOUNT_CODE,
          processing: false
        }
      })
    } catch (error) {
      this.setNotification({
        [NotificationTypes.CART]: {
          severity: NotificationSeverityLevel.ERROR,
          message: "Invalid discount code",
          event: NotificationEvent.APPLYING_DISCOUNT_CODE,
          processing: false
        }
      })
      console.error(error)
      throw new Error("Error adding discount")
    }
  }

  async removeDiscount({ headers = {}, products }) {
    try {
      this.setNotification({
        [NotificationTypes.CART]: {
          severity: NotificationSeverityLevel.INFO,
          message: "Removing discount code",
          event: NotificationEvent.REMOVE_DISCOUNT_CODE,
          processing: true
        }
      })
      const response = await axios({
        method: "put",
        url: `${this.orderUrl}/carts`,
        headers: headers,
        data: {
          deleteDiscount: true,
          products: products
        }
      })
      this.setNotification({
        [NotificationTypes.CART]: {
          severity: NotificationSeverityLevel.INFO,
          message: "Discount code removed",
          event: NotificationEvent.REMOVE_DISCOUNT_CODE,
          processing: false
        }
      })
      return response.data
    } catch (error) {
      this.setNotification({
        [NotificationTypes.CART]: {
          severity: NotificationSeverityLevel.ERROR,
          message: "Problem removing discount code",
          event: NotificationEvent.REMOVE_DISCOUNT_CODE,
          processing: false
        }
      })
      console.error(error)
      throw new Error("Error removing discount")
    }
  }

  async getPaymentMethods({
    headers = {},
    countryCode,
    currencyUnit,
    amount,
    blockedPaymentMethods,
    shopperReference,
    languageCode
  }) {
    try {
      return (
        await axios({
          method: "post",
          url: `${this.orderUrl}/payments/adyen/paymentmethods`,
          headers: headers,
          data: {
            countryCode: countryCode,
            channel: "web",
            shopperReference: shopperReference,
            languageCode: languageCode,
            blockedPaymentMethods: blockedPaymentMethods,
            amount: {
              currency: currencyUnit,
              value: amount
            }
          }
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error getting payment methods")
    }
  }

  async cartToOrder({ headers = {}, email, shippingAddress, billingAddress }) {
    try {
      return (
        await axios({
          method: "post",
          url: `${this.orderUrl}/carts/orders`,
          headers: headers,
          data: {
            email: email,
            shippingAddress: shippingAddress,
            billingAddress: billingAddress
          },
          validateStatus: (status) => [404, 403, 200].includes(status)
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error cart to order")
    }
  }

  async cartToAdyenOrder({
    headers = {},
    email,
    shippingAddress,
    billingAddress,
    blockedPaymentMethods,
    storePaymentMethod,
    shopperReference,
    languageCode,
    returnUrl
  }) {
    try {
      return (
        await axios({
          method: "post",
          url: `${this.orderUrl}/carts/adyen/orders`,
          headers: headers,
          data: {
            email: email,
            shippingAddress: shippingAddress,
            billingAddress: billingAddress,
            blockedPaymentMethods,
            storePaymentMethod,
            shopperReference,
            languageCode,
            returnUrl
          },
          validateStatus: (status) => [404, 403, 200].includes(status)
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error cart to order")
    }
  }

  async cartToKlarnaOrder({ headers = {}, merchantUrls, merchantData, options, shippingAddress }) {
    try {
      return (
        await axios({
          method: "post",
          url: `${this.orderUrl}/carts/klarna/orders`,
          headers,
          data: {
            merchant_urls: merchantUrls,
            merchant_data: merchantData,
            options,
            ...(shippingAddress.postalCode && {
              shipping_address: { postal_code: shippingAddress.postalCode }
            }),
            ...(shippingAddress.postalCode && {
              billing_address: { postal_code: shippingAddress.postalCode }
            })
          },
          validateStatus: (status) => [409, 404, 403, 400, 200].includes(status)
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error cart to klarna order")
    }
  }

  async getKlarnaOrder({ headers = {}, orderId }) {
    try {
      return (
        await axios({
          method: "get",
          url: `${this.orderUrl}/payments/klarna/order?orderId=${orderId}`,
          headers: headers,
          validateStatus: (status) => status === 200
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error get klarna order")
    }
  }

  async makePayment({
    headers = {},
    orderId,
    paymentMethod,
    storePaymentMethod,
    shopperReference,
    browserInfo,
    redirect
  }) {
    try {
      return (
        await axios({
          method: "post",
          url: `${this.orderUrl}/payments/adyen/payments`,
          headers: headers,
          validateStatus: (status) => [404, 200].includes(status),
          data: {
            orderId: orderId,
            paymentMethod: paymentMethod,
            storePaymentMethod: storePaymentMethod,
            shopperReference: shopperReference,
            browserInfo: browserInfo,
            origin: window.location.origin,
            redirect: redirect
          }
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error making payment")
    }
  }

  async updateCartStore({ headers = {}, countryCode, languageCode }) {
    try {
      return (
        await axios({
          method: "put",
          url: `${this.orderUrl}/carts`,
          headers: headers,
          data: {
            store: {
              countryCode: countryCode,
              languageCode: languageCode
            }
          },
          validateStatus: (status) => [404, 403, 400, 200].includes(status)
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error updating cart country")
    }
  }

  async makeDetailsCall({ headers = {}, orderId, details }) {
    try {
      return (
        await axios({
          method: "post",
          url: `${this.orderUrl}/payments/adyen/payments/details`,
          headers: headers,
          data: {
            orderId: orderId,
            details: details
          }
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error making payment details")
    }
  }

  async getShippingOptions({ headers, countryCode }) {
    try {
      return (
        await axios({
          method: "get",
          url: `${this.orderUrl}/shipments/${countryCode}`,
          headers: headers
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error getting shipping options")
    }
  }

  async searchProducts({ query }) {
    try {
      return (
        await axios({
          method: "get",
          url: `${this.productUrl}/products/search?query=${query}`
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error searching products")
    }
  }

  async getCountryCode() {
    try {
      return (
        await axios({
          method: "get",
          url: `${this.orderUrl}/countrycode`
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error getting country code")
    }
  }

  async getOutOfStock() {
    try {
      return (
        await axios({
          method: "get",
          url: `${this.productUrl}/stocks?outOfStock`
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error getting products out of stock")
    }
  }

  async getCart(sessionId) {
    try {
      return (
        await axios({
          method: "get",
          url: `${this.orderUrl}/carts/${sessionId}`
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error getting cart")
    }
  }

  async getStocksV1(productIds) {
    try {
      return (
        await axios({
          method: "get",
          url: `${this.productUrl}/stocks?productIds=${productIds.join(",")}`
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error getting stocks")
    }
  }

  async getStocksV2(productIds, storeId) {
    try {
      return (
        await axios({
          method: "get",
          url: `${this.stockUrl}/products?storeId=${storeId}&productIds=${productIds.join(",")}`
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error getting stocks")
    }
  }

  async ingridDeliveryCheckout(brinkSessionId, searchAddress) {
    return axios({
      method: "post",
      url: `${this.integrationUrl}/integrations/ingrid/delivery-checkout`,
      headers: { Authorization: brinkSessionId },
      data: searchAddress
    }).then(({ data }) => data)
  }

  async getOrderConfirmation(orderId, signature) {
    return axios({
      method: "get",
      url: `${this.orderUrl}/payments/adyen/orders/${orderId}/confirmation`,
      headers: { Authorization: signature }
    }).then(({ data }) => data)
  }
}

const isValidDiscount = (response) => {
  return response.data.cart && response.data.cart.discounts.rules.find((r) => r.ruleType === "DISCOUNTCODE")
}
